import { collectionsKey } from "@/constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import { MEDIAS_PER_PAGE } from "@/features/post/domain/constants";
import collectionUseCases from "@/features/post/domain/use-cases/collection";
import mediaUseCases from "@/features/post/domain/use-cases/media";
import {
  GetCollectionByIdResponse,
  collectionDataSourceImplementation,
} from "@/features/post/infrastructure/datasources/collection/collectionDataSourceImplementation";
import {
  MediaResponse,
  mediaDataSourceImplementation,
} from "@/features/post/infrastructure/datasources/media/mediaDataSourceImplementation";
import { store } from "@/features/post/infrastructure/datastores/store/store";
import { storeImplementation } from "@/features/post/infrastructure/datastores/store/storeImplementation";
import httpImplementation from "@/features/post/infrastructure/services/httpImplementation";

export const useStore = () => {
  const storeImpl = store(storeImplementation());

  return {
    status: storeImpl.status,
    addMedia: storeImpl.addMedia,
  };
};

export const useCollectionByIdInfiniteQuery = () => {
  const httpClient = httpImplementation();
  const collectionDataSourceImpl =
    collectionDataSourceImplementation(httpClient);
  const mediaDataSourceImpl = mediaDataSourceImplementation(httpClient);
  const collectionsUC = collectionUseCases(collectionDataSourceImpl);
  const mediasUC = mediaUseCases(mediaDataSourceImpl);
  const { id } = useParams();

  return useInfiniteQuery({
    queryKey: [collectionsKey, id],
    initialPageParam: "",
    queryFn: async ({ pageParam }) => {
      const promises: Array<Promise<AxiosResponse<MediaResponse>>> = [];

      const collectionResponse = await collectionsUC.getCollectionById<
        AxiosResponse<GetCollectionByIdResponse>
      >({
        id: id || "",
        pagination: {
          perPage: MEDIAS_PER_PAGE,
          offset: pageParam,
        },
      });

      collectionResponse.data.medias.forEach((media) => {
        promises.push(
          mediasUC.getMediaById<AxiosResponse<MediaResponse>>(media.id)
        );
      });

      const promisesResponse = await Promise.all(promises);

      const newMediasUrl: MediaResponse[] = collectionResponse.data.medias.map(
        (media) => {
          const newMedia = promisesResponse.find(
            (newMedia) => media.id === newMedia.data.id
          );

          return {
            ...media,
            url: newMedia?.data.url || media.url,
            preview: newMedia?.data.preview || media.preview,
          };
        }
      );

      const collection: AxiosResponse<GetCollectionByIdResponse> = {
        ...collectionResponse,
        data: {
          ...collectionResponse.data,
          medias: newMediasUrl,
        },
      };

      return collection;
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.pagination.offset || undefined,
    select: (
      data
    ): {
      name: string;
      medias: GetCollectionByIdResponse["medias"];
      itemsTotal: number;
    } => {
      const name = data.pages[0]?.data.name || "";
      const medias = data.pages.flatMap((page) => {
        return page.data.medias.length > 0 ? page.data.medias : [];
      });

      const itemsTotal = data.pages[0]?.data.pagination.itemsTotal || 0;

      return {
        name,
        medias,
        itemsTotal,
      };
    },
  });
};
