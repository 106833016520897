import { useTranslation } from "react-i18next";

import { Text } from "@leeloo/core";

import { Media } from "@/features/post/domain/models/media";

import * as styles from "./mym-cloud-action-bar.css";

export interface MymCloudActionBarProps {
  selectedMedias: Media[];
  button: React.ReactNode;
}

export default function MymCloudActionBar({
  selectedMedias,
  button,
  ...props
}: MymCloudActionBarProps) {
  const { t } = useTranslation();
  return (
    <div
      className={styles.mymCloudActionBar}
      data-testid="mym-cloud-action-bar"
      {...props}
    >
      <Text data-testid="mym-cloud-action-bar-text">
        {t("mymcloud_state_bottombar_selection", {
          selected: selectedMedias.length,
        })}
      </Text>
      {button}
    </div>
  );
}
