import { MediaStatus, MediaType } from "@/features/post/domain/models/media";
import { MediaRepository } from "@/features/post/domain/repositories/media";
import { HttpGateway } from "@/features/post/infrastructure/services/http";

export interface Resolution {
  width: number;
  height: number;
}
export interface CreatePresignedUrlParams {
  media: {
    size: number;
    resolution?: Resolution;
    extension: string;
    inCloud: boolean;
    type: string;
  };
}

export interface CreatePresignedUrlResponse {
  url: string;
  mediaId: string;
}
export interface UploadMediaParams {
  url: string;
  file: File;
}

export interface GetMediasParams {
  pagination: {
    perPage: number;
    offset?: string;
  };
}

export interface MediaResponse {
  id: string;
  url: string;
  preview: string;
  type: MediaType;
  status: MediaStatus;
  inCloud: boolean;
}

export interface GetMediasResponse {
  medias: Array<MediaResponse>;
  pagination: {
    offset: string;
    perPage: number;
    itemsTotal: number;
  };
}

export const mediaDataSourceImplementation = (
  gateway: HttpGateway
): MediaRepository => ({
  createPresignedUrl: async <T>(
    params: CreatePresignedUrlParams
  ): Promise<T> => {
    try {
      return await gateway.post("/posts/media", params);
    } catch (e) {
      throw Error("Error while getting presigned url", {
        cause: e,
      });
    }
  },
  uploadMedia: async <T>(params: UploadMediaParams): Promise<T> => {
    try {
      return await gateway.put(params.url, params.file);
    } catch (e) {
      throw Error("Error while uploading media", {
        cause: e,
      });
    }
  },
  getMedias: async <T>(params: GetMediasParams): Promise<T> => {
    try {
      return await gateway.get(
        `/creators/medias${
          params.pagination.offset
            ? `?perPage=${params.pagination.perPage}&offset=${params.pagination.offset}`
            : `?perPage=${params.pagination.perPage}`
        }`
      );
    } catch (e) {
      throw Error("Error while getting medias", {
        cause: e,
      });
    }
  },
  getMediaById: async <T>(id: string): Promise<T> => {
    try {
      return await gateway.get(`/medias/${id}`);
    } catch (e) {
      throw Error("Error while getting media by id", {
        cause: e,
      });
    }
  },
});
