import { environment } from "@/environments/environment";
import { Auth } from "@aws-amplify/auth";
import axios from "axios";

import { HttpGateway } from "@/features/post/infrastructure/services/http";

export interface ImplementationParams {
  baseURL?: string;
  withInterceptor?: boolean;
}

const httpImplementation = (
  params: ImplementationParams = {
    baseURL: environment.apiUrl,
    withInterceptor: true,
  }
): HttpGateway => {
  const axiosInstance = axios.create({
    baseURL: params.baseURL,
  });

  if (params.withInterceptor) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      async (error: any) => {
        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
};

export default httpImplementation;
