export interface FileProps extends File {
  thumbnailUrl?: string;
}

const imageFormats = ["jpeg", "gif", "png", "jpg", "heic"];
const videoFormats = ["mp4", "mov", "m4v", "quicktime"];

export const checkImageFormat = (file: FileProps | string) => {
  if (typeof file === "string") {
    return imageFormats.some((format) => {
      return file.endsWith(format);
    });
  }

  return imageFormats.some((format) => {
    return file.type.endsWith(format);
  });
};

export const checkVideoFormat = (file: FileProps | string) => {
  if (typeof file === "string") {
    return videoFormats.some((format) => {
      return file.endsWith(format);
    });
  }

  return videoFormats.some((format) => {
    return file.type.endsWith(format);
  });
};

export const isImage = (file: FileProps | string) => {
  if (typeof file === "string") {
    return file.startsWith("image") || file.startsWith("photo");
  }

  return file.type.startsWith("image");
};

export const isVideo = (file: FileProps | string) => {
  if (typeof file === "string") {
    return file.startsWith("video");
  }

  return file.type.startsWith("video");
};

export const isPdf = (file: FileProps | string) => {
  if (typeof file === "string") {
    return file.startsWith("application/pdf");
  }

  return file.type.startsWith("application/pdf");
};

export const isHeic = (file: FileProps | string) => {
  if (typeof file === "string") {
    return file.endsWith("heic");
  }

  return file.type.endsWith("heic");
};
