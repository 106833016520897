import { type BlockedPhrasesRepository } from "@/features/post/domain/repositories/blocked-phrases";
import { type HttpGateway } from "@/features/post/infrastructure/services/http";

export interface GetBlockedPhrasesResponse {
  hidden: Array<string>;
  banned: Array<string>;
}

export const blockedPhrasesDataSourceImplementation = (
  gateway: HttpGateway
): BlockedPhrasesRepository => ({
  checkTextCompliance: async <T>(phrase: string): Promise<T> => {
    try {
      return await gateway.post("/check/text-compliance", {
        text: phrase,
      });
    } catch (e) {
      throw Error("Error while getting blocked phrases", {
        cause: e,
      });
    }
  },
});
