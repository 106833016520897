import { PROFILE } from "@/constants/routes";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, ButtonProps, Logo, Title } from "@leeloo/core";

import { ButtonBack } from "@/components/button-back/ButtonBack";

import { Icon } from "../icon/Icon";
import * as styles from "./layout.css";

interface LayoutProps {
  children: React.ReactNode;
  navbar?: React.ReactNode;
  className?: string;
}

export function Layout({ children, navbar, className, ...props }: LayoutProps) {
  return (
    <div className={clsx(styles.layout, className)} {...props}>
      <>{navbar}</>
      <main className={styles.layoutMain} data-testid="main">
        {children}
      </main>
    </div>
  );
}

interface ContentHeaderProps {
  className?: string;
  title?: string;
  withButtonBack?: boolean;
  withCloseIcon?: boolean;
}

export function ContentHeader({
  className,
  title,
  withButtonBack,
  withCloseIcon,
  ...rest
}: ContentHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        styles.contentHeader({
          displayOnMobile: false,
        }),
        className
      )}
      {...rest}
    >
      {withCloseIcon && (
        <Icon
          className={styles.leftAction}
          name="close"
          data-testid="close-icon"
          onClick={() => navigate(PROFILE)}
        />
      )}
      {withButtonBack && (
        <ButtonBack
          className={styles.leftAction}
          label={t("common_back_button_label")}
          data-testid="button-back"
        />
      )}
      <Title as="h4" data-testid="content-header-title">
        {title}
      </Title>
    </div>
  );
}

interface MobileContentHeaderProps {
  className?: string;
  withLogo?: boolean;
  title?: string;
  withCloseIcon?: boolean;
  withBackButton?: boolean;
  onCloseIconClick?: () => void;
}

export function MobileContentHeader({
  className,
  withLogo = false,
  withCloseIcon = true,
  withBackButton = true,
  title,
  onCloseIconClick,
  ...rest
}: MobileContentHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        styles.contentHeader({
          displayOnMobile: true,
        }),
        className
      )}
      {...rest}
    >
      {withBackButton && (
        <ButtonBack
          label={t("common_back_button_label")}
          className={styles.leftAction}
          data-testid="button-back"
        />
      )}
      {withCloseIcon && (
        <Icon
          className={styles.rightAction}
          size="large"
          name="close"
          data-testid="close-icon"
          onClick={() => {
            if (onCloseIconClick) {
              onCloseIconClick();
            } else {
              navigate(PROFILE);
            }
          }}
        />
      )}
      {withLogo ? (
        <Logo data-testid="logo" className={styles.logo} />
      ) : (
        <Title as="h4" data-testid="mobile-content-header-title">
          {title}
        </Title>
      )}
    </div>
  );
}

interface ContentProps {
  className?: string;
  hasSidebar?: boolean;
  children: React.ReactNode;
}

export function Content({
  className,
  hasSidebar = true,
  children,
  ...rest
}: ContentProps) {
  return (
    <section
      className={clsx(styles.content({ hasSidebar: hasSidebar }), className)}
      {...rest}
    >
      {children}
    </section>
  );
}

interface FloatingActionProps {
  buttonProps?: ButtonProps;
  children: React.ReactNode;
}

export function FloatingAction({
  buttonProps,
  children,
  ...rest
}: FloatingActionProps) {
  return (
    <Button {...buttonProps} className={styles.floatingAction} {...rest}>
      {children}
    </Button>
  );
}
