import {
  LIVE_CONFIGURATION,
  POST_CONFIGURATION,
  PUSH_CONFIGURATION,
} from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Item } from "react-stately";

import { Button, IconName, Logo, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";
import * as styles from "@/components/navigation-bar/navigation-bar.css";

import Menu from "@/features/profile/presentation/containers/menu/Menu";

type MenuItem = {
  label: string;
  icon: IconName;
  href?: string;
};

interface NavigationBarProps {
  menuProfile?: MenuItem[];
  withMobileNavigationBar?: boolean;
  withProfileMenu?: boolean;
}

const NavigationBar = ({
  withProfileMenu = true,
  withMobileNavigationBar = false,
}: NavigationBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const links: {
    name: string;
    href: string;
    icon:
      | "tools"
      | "statistics"
      | "message"
      | "notification"
      | "profile"
      | "settings";
  }[] = [
    {
      name: t("navbar_tools"),
      href: `${window.location.origin}/app/account/tools`,
      icon: "tools",
    },
    {
      name: t("navbar_statistics"),
      href: `${window.location.origin}/app/incomes`,
      icon: "statistics",
    },
    {
      name: t("navbar_messages"),
      href: `${window.location.origin}/app/myms`,
      icon: "message",
    },
    {
      name: t("navbar_profile"),
      href: `${window.location.origin}/app/profile`,
      icon: "profile",
    },
    {
      name: t("notifications_title"),
      href: `${window.location.origin}/app/notifications`,
      icon: "notification",
    },
    {
      name: t("navbar_settings"),
      href: `${window.location.origin}/app/my-account`,
      icon: "settings",
    },
  ];
  interface MenuItem {
    label: string;
    icon: IconName;
    href: string;
  }

  const menu: MenuItem[] = [
    {
      label: "Post",
      icon: "post",
      href: POST_CONFIGURATION,
    },
    {
      label: "Push",
      icon: "push",
      href: PUSH_CONFIGURATION,
    },
    {
      label: "Live",
      icon: "live",
      href: LIVE_CONFIGURATION,
    },
  ];

  return (
    <nav
      className={styles.navigationBar({
        withMobileNavigationBar,
      })}
      data-testid="navigation-bar"
    >
      <ul className={styles.navigationList}>
        <li className={styles.desktopContent}>
          <Logo data-testid="logo" />
        </li>
        <div className={styles.navigationLinks}>
          {links?.map((link, index) => (
            <li key={index}>
              <a
                className={styles.linkItem}
                href={link?.href}
                rel="noreferrer noopenner"
                data-testid={link?.icon}
              >
                <Icon
                  name={link?.icon}
                  size="large"
                  data-testid={`icon-${link?.icon}`}
                />
                <Text
                  className={styles.desktopContent}
                  data-testid={link?.name}
                >
                  {link?.name}
                </Text>
              </a>
            </li>
          ))}
          {withProfileMenu && (
            <Menu
              className={styles.buttonAction}
              onAction={(key) => navigate(`${key}`)}
              popoverProps={{ withUnderlay: true }}
              buttonComponent={
                <Button leftIcon="add" className={styles.buttonAction}>
                  {t("create_new_media_button")}
                </Button>
              }
            >
              {menu?.map((item) => (
                <Item key={item?.href} textValue={item?.label}>
                  <Text>{item.label}</Text>
                  <Icon name={item.icon} size="large" />
                </Item>
              ))}
            </Menu>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default NavigationBar;
