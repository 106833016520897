import { blockedPhrasesKey } from "@/constants/queryKeys";
import { POST_VISIBILITY } from "@/constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { showToast } from "@leeloo/core";

import { blockedPhrasesUseCases } from "@/features/post/domain/use-cases/blocked-phrases";
import {
  GetBlockedPhrasesResponse,
  blockedPhrasesDataSourceImplementation,
} from "@/features/post/infrastructure/datasources/blocked-phrases/blockedPhrasesDataSourceImplementation";
import {
  PostCreationStatus,
  StoreMedia,
  persistedStore,
  store,
} from "@/features/post/infrastructure/datastores/store/store";
import {
  persistedStoreImplementation,
  storeImplementation,
} from "@/features/post/infrastructure/datastores/store/storeImplementation";
import httpImplementation from "@/features/post/infrastructure/services/httpImplementation";
import { uploadPost } from "@/features/post/presentation/pages/setup/formValidation";

export const useStore = () => {
  const storeImpl = store(storeImplementation());

  return {
    media: storeImpl.media,
    caption: storeImpl.caption,
    isPrivate: storeImpl.isPrivate,
    isScheduled: storeImpl.isScheduled,
    status: storeImpl.status,
    addMedia: (file: StoreMedia) => storeImpl.addMedia(file),
    deleteMedia: () => storeImpl.deleteMedia(),
    updateCaption: (caption: string) => storeImpl.updateCaption(caption),
    updateStatus: (status: PostCreationStatus) =>
      storeImpl.updateStatus(status),
    resetStore: () => storeImpl.resetStore(),
  };
};

export const useCheckTextComplianceMutation = () => {
  const blockedPhrasesDataSourceImpl = blockedPhrasesDataSourceImplementation(
    httpImplementation()
  );
  const useCase = blockedPhrasesUseCases(blockedPhrasesDataSourceImpl);

  return useMutation({
    mutationKey: [blockedPhrasesKey],
    mutationFn: (caption: string) =>
      useCase.checkTextCompliance<AxiosResponse<GetBlockedPhrasesResponse>>(
        caption
      ),
  });
};

export const usePersistedStore = () => {
  const persistedStoreImpl = persistedStore(persistedStoreImplementation());

  return {
    isCartridgeDisplayed: persistedStoreImpl.isSetupCartridgeDisplayed,
    setIsCartridgeDisplayed: persistedStoreImpl.setIsSetupCartridgeDisplayed,
  };
};

export const useSetupViewModel = () => {
  const storeImpl = store(storeImplementation());
  const methods = useForm({
    criteriaMode: "all",
    defaultValues: {
      media: storeImpl.media,
      caption: storeImpl.caption,
    },
    mode: "onSubmit",
    resolver: yupResolver(uploadPost),
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutateAsync, isPending } = useCheckTextComplianceMutation();
  const onSubmit = async (data: FieldValues) => {
    try {
      const response = await mutateAsync(data.caption);
      const bannedWords = response.data.banned.concat(response.data.hidden);

      if (bannedWords.length > 0) {
        methods.setError("caption", {
          type: "custom",
          message: t("global_error_blocked_words", {
            0: bannedWords.join(", "),
          }),
        });

        return;
      }

      navigate(POST_VISIBILITY);
      storeImpl.updateStatus(PostCreationStatus.VISIBILITY);
    } catch (e) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });

      throw e;
    }
  };

  return {
    formMethods: methods,
    isLoading: isPending,
    onSubmit,
  };
};
