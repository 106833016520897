import { isImage, isVideo } from "./fileFormat";

export const getMediaResolution = async (
  file: File,
  minHeight: number,
  minWidth: number
): Promise<{ width: number; height: number }> => {
  const fileUrl = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const checkResolution = (width: number, height: number) => {
      if (width < minWidth || height < minHeight) {
        reject({
          type: "MINIMUM_DIMENSION",
          message: `Minimum resolution is ${minHeight} x ${minWidth}`,
        });
      } else {
        resolve({ width, height });
      }
    };

    if (isImage(file)) {
      const image = new Image();
      image.src = fileUrl;
      image.onload = () => {
        checkResolution(image.width, image.height);
      };
      image.onerror = () => reject(new Error("Failed to load image"));
    } else if (isVideo(file)) {
      const video = document.createElement("video");
      video.src = fileUrl;

      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = () => reject(new Error("Failed to load video"));
    } else {
      reject(new Error("Unsupported file type"));
    }
  });
};

type CreateVideoPosterProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
};

export const createVideoPoster = ({
  videoRef,
  canvasRef,
}: CreateVideoPosterProps): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = videoRef.current;
    if (!video) {
      return reject(new Error("Video element not found."));
    }

    const handleTimeUpdate = () => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (canvas && context) {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        const imageUrl = canvas.toDataURL("image/jpeg");

        resolve(imageUrl);

        video.removeEventListener("timeupdate", handleTimeUpdate);
      } else {
        reject(new Error("Canvas context is not available."));
      }
    };

    const handleLoadedData = () => {
      video.currentTime = 0.5;
    };

    video.addEventListener("loadeddata", handleLoadedData);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadeddata", handleLoadedData);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  });
};
