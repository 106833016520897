import { StoreApi, create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { PersistedPostSlice, PostCreationStatus, PostSlice } from "./store";

const defaultState = {
  media: null,
  caption: "",
  isPostConfirmed: false,
  isPrivate: true,
  isScheduled: false,
  status: PostCreationStatus.SETUP,
  publicationDate: null,
};

export const storeImplementation = create<PostSlice>()((set) => ({
  ...defaultState,
  updateCaption: (caption) => set((state) => ({ ...state, caption })),
  addMedia: (media) => set(() => ({ media: media })),
  deleteMedia: () => {
    set((state) => ({ ...state, media: null }));
  },
  resetStore: () => {
    set(defaultState);
  },
  updateIsPostConfirmed: (isPostConfirmed: boolean) =>
    set((state) => ({ ...state, isPostConfirmed })),
  updateIsPrivate: (isPrivate: boolean) => {
    set((state) => ({ ...state, isPrivate }));
  },
  updateIsScheduled: (isScheduled: boolean) =>
    set((state) => ({ ...state, isScheduled })),
  updatePublicationDate: (publicationDate: Date) =>
    set((state) => ({ ...state, publicationDate })),
  updateStatus: (status: PostCreationStatus) => {
    set((state) => ({
      ...state,
      status: status,
    }));
  },
}));

export const persistedStoreImplementation = create<PersistedPostSlice>()(
  persist(
    (set) => ({
      isSetupCartridgeDisplayed: true,
      isVisibilityCartridgeDisplayed: true,
      setIsSetupCartridgeDisplayed: (value: boolean) =>
        set({
          isSetupCartridgeDisplayed: value,
        }),
      setIsVisibilityCartridgeDisplayed: (value: boolean) =>
        set({
          isVisibilityCartridgeDisplayed: value,
        }),
    }),
    {
      name: "post", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
