import MediaGridSelectionItem from "@/components/media-grid-selection/media-grid-selection-item";

import { Media } from "@/features/post/domain/models/media";

import * as styles from "./media-grid-selection.css";

export interface MediaGridSelectionProps {
  medias: Media[];
  onSelectItem: (media?: Media) => void;
  selectedMedias: Media[];
}

export default function MediaGridSelection({
  medias,
  onSelectItem,
  selectedMedias,
}: MediaGridSelectionProps) {
  return (
    <div className={styles.panel} data-testid="media-grid-panel">
      {medias?.map((media: Media, index: number) => (
        <MediaGridSelectionItem
          data-testid="media-grid-selection-item"
          media={media}
          key={index}
          disabled={
            selectedMedias?.length
              ? !selectedMedias.some(
                  (selectedMedia) => selectedMedia.id === media.id
                )
              : false
          }
          checked={!!selectedMedias.find((medium) => medium.id === media.id)}
          onSelectItem={(mediaId: string) => {
            onSelectItem(medias.find((media: Media) => media.id === mediaId));
          }}
        />
      ))}
    </div>
  );
}
