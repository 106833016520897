import React, { Suspense } from "react";

import { environment } from "@/environments/environment";
import "@/translations";
import "@/utils/yup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import "normalize.css";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";

import { Loader, Theme } from "@leeloo/core";
import "@leeloo/core/dist/index.css";

import "@/services/rudderstack";
import "@/services/sentry";

import { amplify } from "./config/amplify";
import { Router } from "./routes/routes";

Amplify.configure(amplify);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Suspense fallback={<Loader spin={true} />}>
      <QueryClientProvider client={queryClient}>
        <Theme.Provider
          value={{
            theme: "dark",
            iconPath: "/build/korben/assets/sprite.svg",
            illustrationPath: `${environment.cdnUrl}/assets/illustrations`,
          }}
        >
          <Toaster position="top-right" />
          <Router />
        </Theme.Provider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>
);
