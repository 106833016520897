import { useEffect } from "react";

import { Loader } from "@leeloo/core";

import * as styles from "./infinite-scroll.css";

interface InfiniteScrollProps {
  children: React.ReactNode;
  hasMore: boolean;
  loadMore: () => void;
  isLoading: boolean;
}

const InfiniteScroll = ({
  children,
  hasMore,
  loadMore,
  isLoading,
  ...props
}: InfiniteScrollProps) => {
  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollHeight - 50 <
        window.scrollY + window.innerHeight
      ) {
        if (!isLoading && hasMore) {
          loadMore();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore]);

  return (
    <div data-testid="infinite-scroll" {...props}>
      {children}

      {isLoading && (
        <Loader
          spin={true}
          className={styles.loader}
          data-testid="infinite-scroll-loader"
        />
      )}
    </div>
  );
};

export default InfiniteScroll;
