import {
  checkImageFormat,
  checkVideoFormat,
  isImage,
  isVideo,
} from "@/utils/fileFormat";
import { getMediaResolution } from "@/utils/mediaResolution";
import * as yup from "yup";

import {
  MAXIMUM_IMAGE_SIZE,
  MAXIMUM_VIDEO_SIZE,
  MAX_DESCRIPTION_CHARACTERS,
  MIN_IMAGE_DIMENSION,
} from "@/features/post/domain/constants";

export const uploadPost = yup.object({
  media: yup
    .mixed()
    .test("fileFormat", "mandatory_field_is_empty_error", (value) => {
      return value.properties && value.properties.url && value.properties.type;
    }),
  caption: yup
    .string()
    .max(MAX_DESCRIPTION_CHARACTERS, "post_caption_input_limit_error"),
});

export const fileValidation = yup
  .object({
    media: yup.mixed().required(),
  })
  .test("imageSize", "post_image_file_size_error", (value) => {
    return isImage(value.media) ? value.media.size <= MAXIMUM_IMAGE_SIZE : true;
  })
  .test(
    "imageMinimumResolution",
    "media resolution is too small",
    async (value) => {
      try {
        if (isVideo(value?.media)) {
          return true;
        }
        const resolution = await getMediaResolution(
          value.media,
          MIN_IMAGE_DIMENSION,
          MIN_IMAGE_DIMENSION
        );

        return (
          resolution.width >= MIN_IMAGE_DIMENSION ||
          resolution.height >= MIN_IMAGE_DIMENSION
        );
      } catch (error) {
        return false;
      }
    }
  )
  .test("videoSize", "post_video_file_size_error", (value) => {
    return isVideo(value?.media)
      ? value?.media.size <= MAXIMUM_VIDEO_SIZE
      : true;
  })
  .test("imageFormat", "post_invalid_image_format_error", (value) => {
    return isImage(value?.media) ? checkImageFormat(value?.media) : true;
  })
  .test("fileVideoFormat", "post_invalid_video_format_error", (value) => {
    return isVideo(value?.media) ? checkVideoFormat(value?.media) : true;
  })
  .test("fileFormat", "post_invalid_format_error", (value) => {
    return isImage(value?.media) || isVideo(value?.media);
  })
  .required();
