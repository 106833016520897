import React from "react";

import { Checkbox, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import { Media } from "@/features/post/domain/models/media";

import * as styles from "./media-grid-selection-item.css";

export interface MediaGridSelectionItemProps {
  media: Media;
  checked: boolean;
  disabled?: boolean;
  onSelectItem: (mediaId: string) => void;
}

const MediaGridSelectionItem = ({
  media,
  checked,
  disabled,
  onSelectItem,
  ...props
}: MediaGridSelectionItemProps) => {
  const formatTime = (seconds: number) =>
    new Date(seconds * 1000).toISOString().substring(14, 19);

  return (
    <div
      className={styles.media({ disabled: disabled })}
      onClick={() => onSelectItem(media.id)}
      data-testid="media-grid-selection-item"
      {...props}
    >
      <span
        data-testid="mgsi-overlay"
        className={styles.overlay({ selected: checked })}
      />
      <Checkbox
        data-testid="mgsi-checkbox"
        className={styles.checkbox({ checked: checked })}
        value={media.id}
        key={media.id}
        aria-label="Selected media to create a post"
        onChange={() => onSelectItem(media.id)}
        checked={checked}
      />
      <img
        data-testid="mgsi-image"
        src={media.preview}
        className={styles.image}
        alt="Your media"
        loading="lazy"
      />
      {media.type === "video" && (
        <div className={styles.duration} data-testid="mgsi-duration">
          <Icon
            name="camera"
            aria-label="Video icon"
            size="small"
            data-testid="mgsi-duration-icon"
          />
          <Text
            className={styles.text}
            variant="caption"
            data-testid="mgsi-duration-text"
          >
            {formatTime(media.duration || 0)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default MediaGridSelectionItem;
