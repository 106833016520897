import {
  CollectionRepository,
  GetCollectionByIdParams,
  GetCollectionsParams,
} from "@/features/post/domain/repositories/collection";

export const collectionUseCases = (repository: CollectionRepository) => ({
  getCollections: async <T>(params: GetCollectionsParams): Promise<T> => {
    try {
      return await repository.getCollections<T>(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getCollectionById: async <T>(params: GetCollectionByIdParams): Promise<T> => {
    try {
      if (!params.id) {
        throw Error("collection id is required");
      }

      return await repository.getCollectionById<T>(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default collectionUseCases;
