export const MIN_IMAGE_DIMENSION = 100;
export const MAX_DESCRIPTION_CHARACTERS = 1500;
export const MAXIMUM_VIDEO_DURATION = 10800;
export const MAXIMUM_IMAGE_SIZE = 31457280;
export const MAXIMUM_VIDEO_SIZE = 5368709120;
export const ALLOWED_EXTENSION = [
  "jpeg",
  "jpg",
  "gif",
  "png",
  "mp4",
  "mov",
  "m4v",
  "quicktime",
];

export const MAX_MEDIAS_POST = 1;

export const MEDIAS_PER_PAGE = 20;
export const COLLECTIONS_PER_PAGE = 20;
