import { BlockedPhrasesRepository } from "@/features/post/domain/repositories/blocked-phrases";

export const blockedPhrasesUseCases = (
  repository: BlockedPhrasesRepository
) => ({
  checkTextCompliance: async <T>(text: string): Promise<T> => {
    try {
      return await repository.checkTextCompliance(text);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});
