import {
  ALLOWED_EXTENSION,
  MAXIMUM_IMAGE_SIZE,
  MIN_IMAGE_DIMENSION,
} from "@/features/post/domain/constants";
import {
  CreatePresignedUrlParams,
  GetMediasParams,
  MediaRepository,
  UploadMediaParams,
} from "@/features/post/domain/repositories/media";

export const mediaUseCases = (repository: MediaRepository) => ({
  getMedias: async <T>(params: GetMediasParams): Promise<T> => {
    try {
      return await repository.getMedias(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getMediaById: async <T>(id: string): Promise<T> => {
    try {
      return await repository.getMediaById(id);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  createPresignedUrl: async <T>(
    params: CreatePresignedUrlParams
  ): Promise<T> => {
    try {
      if (!params.media) {
        throw Error("media params is required");
      }

      if (!params.media.size) {
        throw Error("media.size params is required");
      } else if (
        params.media.type === "image" &&
        params.media.size > MAXIMUM_IMAGE_SIZE
      ) {
        throw Error("media exceed the maximum size");
      }

      if (!params.media.resolution) {
        throw Error("media.resolution params is required");
      } else if (
        params.media.type === "image" &&
        (params.media.resolution.width < MIN_IMAGE_DIMENSION ||
          params.media.resolution.height < MIN_IMAGE_DIMENSION)
      ) {
        throw Error("media resolution is too small");
      }

      if (!params.media.resolution.width) {
        throw Error("media.resolution.width params is required");
      }

      if (!params.media.resolution.height) {
        throw Error("media.resolution.height params is required");
      }

      if (!params.media.extension) {
        throw Error("media.extension params is required");
      } else if (
        !ALLOWED_EXTENSION.find((extension) => {
          return extension === params.media.extension;
        })
      ) {
        throw Error("media does not have the correct format");
      }

      if (params.media.inCloud === null || params.media.inCloud === undefined) {
        throw Error("media.inCloud params is required");
      }

      if (params.media.extension === "quicktime") {
        params.media.extension = "mov";
      }

      return await repository.createPresignedUrl(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  uploadMedia: async <T>(params: UploadMediaParams): Promise<T> => {
    try {
      {
        if (!params.url) {
          throw Error("media.url params is required");
        }

        if (!params.file) {
          throw Error("media.file params is required");
        }
      }
      return await repository.uploadMedia(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default mediaUseCases;
